import React, { useEffect } from 'react';
import { Card, Container, Grid, Stack, Box, Typography, Paper, ListItem, Chip } from '@mui/material';
import { useParams } from 'react-router';
import useTask from '../useTask';
import Form from '../../../components/Form';
import Page from '../../../components/Page';
import TaskDetailEditor from '../TaskDetailEditor';
import Task from '../../../models/Task';
import { TaskStatus, taskStatusOptions, extendedTaskStatusOptions } from '../../../enums/TaskStatus';
import SelectField from '../../../components/Form/fields/SelectField';
import { LoadingButton } from '@mui/lab';
import useUpdateTask from '../useUpdateTask';
import TaskInfo from '../TaskInfo';
import TextField from '../../../components/Form/fields/TextField';
import CustomerPricing from '../CustomerPricing';
import FileByType from '../FilesByType';
import { FileType } from '../../../enums/FileType';
import Modifications from '../Modifications';
import TranslatorPricing from '../TranslatorPricing';
import CommentsSection from '../CommentsSection';
import TaskLoading from '../TaskLoading';
import PostalAddressEditor from '../PostalAddressEditor/PostalAddressEditor';
import Customer from '../../../models/Customer';
import { useQuery } from 'react-query';
import SuggestionSection from '../Suggestions';
import useSeenTask from '../useSeenTask';
import { UserRole } from '../../../enums/UserRole';


type AdminEditorProps = {
    users: { userId: string, roomId: string, name: string, role: UserRole }[] | undefined;
};

function AdminEditor({ users }: AdminEditorProps) {
    const { id: taskId } = useParams();
    const { data: task } = useTask(taskId);
    const { data: customer } = useQuery<Customer>(['customers/', task?.customerId], { enabled: !!task?.customerId });
    const { mutate: updateTask } = useUpdateTask(taskId);
    const { mutate: setSeen } = useSeenTask();

    useEffect(() => {
        setSeen({ id: taskId });
    }, [setSeen, taskId]);


    if (!task) {
        return <TaskLoading />;
    }

    function onKeyDown(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
        if (keyEvent.key === 'Enter' || keyEvent.key === '13') {
            keyEvent.preventDefault();
        }
    }

    return (
        <Page title="WeTranslate" >
            <Form<Task>
                validationSchema={undefined} onSubmit={updateTask} initialValues={task} onKeyDown={onKeyDown}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Card sx={{ p: 2 }}>
                                    <TaskInfo task={task} isCustomer={false} isAdmin={true} />
                                </Card>
                                <FileByType
                                    fileType={FileType.AUTOTRANSLATION}
                                    taskId={taskId} />
                                <FileByType
                                    fileType={FileType.TRANSLATED}
                                    taskId={taskId} />
                                <FileByType
                                    fileType={FileType.ORIGINAL}
                                    taskId={taskId}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                    <TaskDetailEditor showTranslationOptions adminOptions />
                                </Card>
                                {task.hardCopyRequired && (
                                    <Card>
                                        <PostalAddressEditor />
                                    </Card>
                                )}
                                <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                    <CustomerPricing taskId={taskId} />
                                </Card>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <SelectField
                                    options={customer?.mustApprovePrice ? extendedTaskStatusOptions : taskStatusOptions}
                                    name={'status'}
                                    label={'Status'}
                                />
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    type={'submit'}>
                                    Save task
                                </LoadingButton>

                                <CommentsSection />

                                <Card sx={{ p: 2 }}>
                                    <TranslatorPricing />
                                </Card>

                                <Modifications taskId={taskId} />

                                <SuggestionSection />

                                {task.hardCopyRequired && (
                                    <Card sx={{ p: 2 }}>
                                        <TextField name={'trackingNumber'} label={'Tracking number'} />
                                    </Card>
                                )}

                                <Card sx={{ p: 2 }}>
                                    <TextField name={'merchantId'} label={'Merchant ID'} />
                                </Card>

                                {(task.status === TaskStatus.PRICE_DECLINED || task.status === TaskStatus.INCORRECT_TRANSLATION) && task.explanation ? (
                                    <Card sx={{ p: 2 }}>
                                        <Box sx={{ m: 2 }}>
                                            <Typography variant='subtitle2'
                                                color='text.secondary'
                                                gutterBottom>Customer explanation for declined Task:</Typography>
                                            <Typography variant='subtitle2' sx={{ ml: 1 }}>{task.explanation}</Typography>
                                        </Box>
                                    </Card>
                                ) : null}

                                <Card sx={{ p: 2 }}>
                                    <Stack>
                                        <Typography alignSelf={'flex-start'} variant='subtitle2' color='text.secondary' gutterBottom>Currently watching</Typography>
                                        <Grid container spacing={1}>
                                            {users && users.map((user) => (
                                                <Grid item xs={6} key={user.userId}>
                                                    <Stack direction={'row'} gap={1}>
                                                        <Chip
                                                            color="info"
                                                            size="small"
                                                            label={`${user.name} (${user.role})`}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Stack>
                                </Card>

                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Form>
        </Page>
    );
}

export default AdminEditor;
