import React, { ReactNode } from 'react';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, styled } from '@mui/material/styles';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';
import { AppBar, Box, Button, IconButton, ListItemButtonProps, Stack, Toolbar } from '@mui/material';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { MHidden } from '../../../components/@material-extend';
import AccountPopover from '../AccountPopover';
import useMessagingSubscription from '../../../hooks/useMessagingSubscription';
import MessageNotification from './MessageNotification';
import useMe from '../../../hooks/useMe';
import useChatAdmin from '../../../hooks/useChatAdmin';
import CodeInfo from './CodeInfo';


const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const ListItemStyle = styled(Button)<ListItemStyleProps>(({ theme }) => ({
    ...theme.typography.body2,
    'textTransform': 'capitalize',
    'color': theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

type NavItemProps = {
  title: string;
  path: string;
};

function NavItem({ title, path }: NavItemProps) {
    const { pathname } = useLocation();
    const isActiveRoot = path ? !!matchPath(path, pathname) : false;

    const activeRootStyle = {
        'color': 'primary.main',
        'fontWeight': 'fontWeightMedium',
        '&:before': { display: 'block' },
    };

    return (
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            {title}
        </ListItemStyle>
    );
}

export default function Navbar({ onOpenSidebar }: DashboardNavbarProps): JSX.Element {
    const { isCollapse } = useCollapseDrawer();
    const { data: me } = useMe();
    const { data: chatAdminUser } = useChatAdmin();

    const isMessagingAdmin = me?.isAdmin && me?.id === chatAdminUser?.id;
    const isNonAdmin = !me?.isAdmin;
    const isShowMessaging = isNonAdmin || isMessagingAdmin;

    return (
        <RootStyle
            sx={{
                ...(isCollapse && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
                }),
            }}
        >
            <ToolbarStyle>
                <MHidden width="lgUp">
                    <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden>

                <Box sx={{ flexGrow: 1 }}>
                    {me?.isAdmin && (
                        <MHidden width="mdDown">
                            <Stack direction='row' spacing={1}>
                                <NavItem title={'Dashboard'} path={'/'}/>
                                <NavItem title={'Tasks'} path={'/tasks'} />
                                <NavItem title={'Users'} path={'/users'} />
                                <NavItem title={'Customers'} path={'/customers'} />
                                <NavItem title={'Summary'} path={'/summary'} />
                                <NavItem title={'Reports'} path={'/reports'} />
                                {/* <NavItem title={'Invoice'} path={'/invoice'} /> */}
                                <NavItem title={'Settings'} path={'/settings'} />
                            </Stack>
                        </MHidden>
                    )}
                    {me?.isAgent && (
                        <MHidden width="mdDown">
                            <Stack direction='row' spacing={1}>
                                <NavItem title={'Tasks'} path={'/tasks'} />
                                <NavItem title={'Management'} path={`/customers/${me?.customerId}`} />
                                <NavItem title={'Summary'} path={`/customer-summary`} />
                                {/* <NavItem title={'Invoice'} path={`/invoice`} /> */}
                            </Stack>
                        </MHidden>
                    )}
                </Box>

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    {me?.isAdmin && (
                        <CodeInfo />
                    )}
                    {isShowMessaging && <MessageNotification/>}
                    <AccountPopover />
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
