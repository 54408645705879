import React, { useEffect, useState } from 'react';
import useMe from '../../hooks/useMe';
import { UserRole } from '../../enums/UserRole';
import AdminEditor from './EditorByRole/Editor.admin';
import LinguistEditor from './EditorByRole/Editor.linguist';
import TranslatorEditor from './EditorByRole/Editor.translator';
import AgentEditor from './EditorByRole/Editor.agent';
import UserEditor from './EditorByRole/Editor.user';
import TaskLoading from './TaskLoading';
import useSocket from '../../contexts/SocketContext';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const TaskEditor = () => {
    const { id: taskId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { data: me } = useMe();
    const socket = useSocket();
    const [activeUsers, setActiveUsers] = useState<{ userId: string, roomId: string, name: string, role: UserRole }[]>();

    useEffect(() => {
        socket?.emit('CONNECTED_TO_TASKROOM', { roomId: taskId });
        return () => {
            socket?.emit('DISCONNECTED_FROM_TASKROOM', { roomId: taskId });
        };
    }, []);

    useEffect(() => {
        socket?.on('roomData', ({ users, anotherAdminWatching }) => {
            setActiveUsers(users);
            if (anotherAdminWatching && me && me.role === UserRole.ADMIN) {
                enqueueSnackbar('Another Admin is watching this task', { variant: 'info' });
            }
        });
    }, []);

    switch (me && me.role) {
        case UserRole.ADMIN:
            return <AdminEditor users={activeUsers} />;
        case UserRole.LINGUIST:
            return <LinguistEditor />;
        case UserRole.TRANSLATOR:
            return <TranslatorEditor />;
        case UserRole.AGENT:
            return <AgentEditor />;
        case UserRole.USER:
            return <UserEditor />;
        default:
            return <TaskLoading />;
    }
};

export default TaskEditor;
