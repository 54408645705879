import React from 'react';
import useFilesByTask from './useFilesByTask';
import ListFiles from '../../../components/ListFiles';
import { FileType } from '../../../enums/FileType';
import File from '../../../models/File';
import useMe from '../../../hooks/useMe';
import { TaskStatus } from '../../../enums/TaskStatus';
import { Box, Card, Chip, Stack, Typography } from '@mui/material';
import FileUpload from '../FileUpload';
import { UserRole } from '../../../enums/UserRole';
import numeral from 'numeral';
import DataLoader from './DataLoader';

type Props = {
    taskId?: string;
    taskStatus?: TaskStatus;
    fileType: FileType
}

const FileByType: React.FC<Props> = ({ taskId, fileType, taskStatus }) => {
    const { data: files } = useFilesByTask(fileType, taskId);
    const { data: currentUser } = useMe();

    const checkUploadAccess = (role?: UserRole) => {
        if (fileType === FileType.TRANSLATED) {
            return role && role !== UserRole.AGENT && role !== UserRole.USER;
        } else if (fileType === FileType.ORIGINAL) {
            return role && role !== UserRole.LINGUIST && role !== UserRole.TRANSLATOR;
        } else {
            return false;
        }
    };

    if (files?.length === 0 && checkUploadAccess(currentUser?.role)) {
        return <FileUpload taskId={taskId} type={fileType} />;
    } else if (files?.length === 0 && !checkUploadAccess(currentUser?.role)) {
        return (
            <Card sx={{ p: 2 }}>
                <Stack alignItems={'center'}>
                    <Typography alignSelf={'flex-start'}
                        variant='subtitle2' color='text.secondary' gutterBottom>Translation File</Typography>
                    <Stack alignItems={'center'} justifyContent={'center'} gap={2}>
                        <img width={140} alt="empty content"
                            src="/images/illustration_empty_content.svg" />
                        <Typography variant={'subtitle2'}>Not available yet</Typography>
                    </Stack>
                </Stack>
            </Card>
        );
    }

    const getFileCharacterNumber = (files?: File[]) => {
        if (files && files[0] && files[0].parseStatus === 'parsed') {
            if (fileType !== FileType.AUTOTRANSLATION && files[0].originalCharacterCount) {
                return numeral(files[0].originalCharacterCount).format(',');
            } else if (files[0].autoTranslationCharacterCount) {
                return numeral(files[0].autoTranslationCharacterCount).format(',');
            } else {
                return 0;
            }
        }
    };

    const deletionChecker = (type: FileType, files?: File[]) => {
        if (files && files[0] && files?.[0].parseStatus === 'parsing') {
            return true;
        } else if (type === FileType.AUTOTRANSLATION) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Card sx={{ p: 2 }}>
            <Box>
                <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} mb={1}>
                    <DataLoader fileType={fileType} files={files} currentUser={currentUser} taskId={fileType === FileType.AUTOTRANSLATION ? taskId : undefined} />
                    {getFileCharacterNumber(files) && getFileCharacterNumber(files) !== 0 && <Chip variant={'outlined'} size={'small'} color={'info'} label={`${getFileCharacterNumber(files)} characters`} />}
                </Stack>
                <ListFiles
                    disableDelete={deletionChecker(fileType, files)}
                    files={files ?? []}
                    previewable={true}
                    downloadable={true}
                    taskId={taskId}
                    taskStatus={taskStatus}
                    currentUser={currentUser}
                    translation={fileType === FileType.TRANSLATED ? true : false}
                />
            </Box>
        </Card>
    );
};

export default FileByType;
