import { FileType } from '../enums/FileType';
import EntityBase from './EntityBase';
import Task from './Task';
import User from './User';

export type TranslationFileParseStatus = 'idle' | 'parsing' | 'parsed' | 'error';

export default class File extends EntityBase {
    public id?: string;
    public filename?: string;
    public uploadedDate?: string;
    public size?: number;
    public type?: FileType;
    public taskId?: string;
    public task?: Task;
    public userId?: string;
    public user?: User;
    public originalCharacterCount?: number | null;
    public autoTranslationCharacterCount?: number | null;
    public parseStatus?: TranslationFileParseStatus;
}
