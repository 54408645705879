import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import TextField from '../../../components/Form/fields/TextField';
import PricingComponent from './PricingComponent';
import SwitcherFieldRevert from '../../../components/Form/fields/SwitcherFieldRevert';
import SwitcherField from '../../../components/Form/fields/SwitcherField';

const CustomerPricing: React.FC<{ taskId?: string }> = () => {
    const [field] = useField('customCustomerPrice');


    return useMemo(() => {
        const isPricingComponentHidden = field.value;
        return (
            <Stack alignItems={'center'}>
                <Typography alignSelf={'flex-start'}
                    variant='subtitle2' color='text.secondary' gutterBottom>Customer pricing</Typography>
                {isPricingComponentHidden ?
                    <TextField label={'Customer price'} name={'price'} /> :
                    <PricingComponent />}
                <Stack direction={'row'} gap={2}>
                    <SwitcherFieldRevert label={'Free task'} name={'include'} />
                    <SwitcherField label={'Set manually'} name={'customCustomerPrice'} />
                </Stack>
            </Stack>
        );
    }, [field.value]);
};

export default CustomerPricing;
